import { createWebHistory, createRouter } from "vue-router";
import SignIn from "@/components/auth/SignIn.vue";

const authGuard = (to, from, next) => {
  var isAuthenticated = false;
  let user = JSON.parse(localStorage.getItem("user"));
  if (user.access_token) isAuthenticated = true;
  else isAuthenticated = false;
  if (isAuthenticated) next();
  else next("/login");
};

const routes = [
  { path: "/", component: SignIn, meta: { title: "Sign In" } },
  { path: "/login", component: SignIn, meta: { title: "Sign In" } },
  { path: "/forget", component: () => import("@/components/auth/Forget.vue"), beforeEnter: authGuard, meta: { title: 'Forget Credentials' } },
  {
    path: "/landing-page",
    component: () => import("@/components/LandingPage.vue"),
    beforeEnter: authGuard,
    meta: { title: "Landing Page" },
  },
  //client Mangement
  {
    path: "/client-management",
    component: () => import("@/components/client/Clients.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients Management" },
  },
  {
    path: "/referrals",
    component: () => import("@/components/client/Referrals.vue"),
    beforeEnter: authGuard,
    meta: { title: "Referrals Management" },
  },
  {
    path: "/operators-openroads",
    component: () => import("@/components/client/CarrierClients.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients Management" },
  },
  {
    path: "/client-profile",
    component: () => import("@/components/client/Profile.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients Management" },
  },
  {
    path: "/operator-profile",
    component: () => import("@/components/client/CarrierProfile.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients Management" },
  },
  {
    path: "/client-users",
    component: () => import("@/components/client/SystemUsers.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients Management" },
  },
  {
    path: "/client-groups",
    component: () => import("@/components/client/Group.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients Management" },
  },
  {
    path: "/client-subsidiaries",
    component: () => import("@/components/client/Subsidiaries.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients Management" },
  },
 
  {
    path: "/client-signups",
    component: () => import("@/components/client/Signups.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients Management" },
  },
  {
    path: "/client-categories",
    component: () => import("@/components/client/Categories.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients Management" },
  },
  
  {
    path: "/add-client",
    component: () => import("@/components/client/NewClient.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients Management" },
  },
 
  //transaction Mangement
    {
    path: "/transactions",
    component: () =>
      import("@/components/transaction/CarrierTransactions.vue"),
    beforeEnter: authGuard,
    meta: { title: "Operator Transactions" },
  },
    {
    path: "/disputed-transactions",
    component: () =>
      import("@/components/transaction/DisputedTransactions.vue"),
    beforeEnter: authGuard,
    meta: { title: "Operator Transactions" },
  },
   {
    path: "/weekly-report",
    component: () => import("@/components/transaction/WeeklyReport.vue"),
    beforeEnter: authGuard,
    meta: { title: "Weekly Reports" },
  },
  {
    path: "/disputed-transactions",
    component: () =>
      import("@/components/transaction/dispute.vue"),
    beforeEnter: authGuard,
    meta: { title: "Disputed Transactions" },
  },
  {
    path: "/enterprise-transactions",
    component: () =>
      import("@/components/transaction/EnterpriseTransactions.vue"),
    beforeEnter: authGuard,
    meta: { title: "Enterprise Transactions" },
  },
  {
    path: "/enterprise-transaction-dumps",
    component: () =>
      import("@/components/transaction/EnterpriseTransactionDumps.vue"),
    beforeEnter: authGuard,
    meta: { title: "Enterprise Transaction Dumps" },
  },
  {
    path: "/carrier-transaction-dumps",
    component: () =>
      import("@/components/transaction/CarrierTransactionDumps.vue"),
    beforeEnter: authGuard,
    meta: { title: "Operator Transaction Dumps" },
  },
  {
    path: "/failed-replenish",
    component: () => import("@/components/transaction/Replenish.vue"),
    beforeEnter: authGuard,
    meta: { title: "Failed Credit Card Replenish" },
  },
  {
    path: "/enterprise-dumps",
    component: () => import("@/components/transaction/EnterpriseDumps.vue"),
    beforeEnter: authGuard,
    meta: { title: "Enterprise Dumps" },
  },
  {
    path: "/carrier-dumps",
    component: () => import("@/components/transaction/CarrierDumps.vue"),
    beforeEnter: authGuard,
    meta: { title: "Operator Dumps" },
  },
 
  {
    path: "/monthly-invoices",
    component: () => import("@/components/transaction/MonthlyInvoices.vue"),
    beforeEnter: authGuard,
    meta: { title: "Monthly Invoices" },
  },
  {
    path: "/client-uploads",
    component: () => import("@/components/vehicle/ClientUploads.vue"),
    beforeEnter: authGuard,
    meta: { title: "Client Vehicle Uploads", back: "vehicle-management" },
  },
  {
    path: "/client-lists",
    component: () => import("@/components/clientView/ClientLists.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients List" },
  },
 
  {
    path: "/balance",
    component: () => import("@/components/transaction/Accounts.vue"),
    beforeEnter: authGuard,
    meta: { title: "Account Balance Management" },
  },
  {
    path: "/fulfillments",
    component: () => import("@/components/transaction/Fulfillments.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transponder Fulfillments" },
  },
  {
    path: "/single-request",
    component: () => import("@/components/transaction/SingleRequest.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transponder Request" },
  },
  {
    path: "/card-details",
    component: () => import("@/components/transaction/CardDetails.vue"),
    beforeEnter: authGuard,
    meta: { title: "Payment Card Management" },
  },
  {
    path: "/top-ups",
    component: () => import("@/components/transaction/TopUpRequests.vue"),
    beforeEnter: authGuard,
    meta: { title: "Top Up Requests" },
  },
  {
    path: "/carrier-single-request",
    component: () =>
      import("@/components/transaction/CarrierSingleRequest.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transponder Request" },
  },
  {
    path: "/carrier-card-details",
    component: () => import("@/components/transaction/CarrierCardDetails.vue"),
    beforeEnter: authGuard,
    meta: { title: "Payment Card Management" },
  },

  {
    path: "/invoices",
    component: () => import("@/components/transaction/CarrierInvoices.vue"),
    beforeEnter: authGuard,
    meta: { title: "Operator Invoices Management" },
  },
  {
    path: "/citations",
    component: () => import("@/components/transaction/CarrierCitations.vue"),
    beforeEnter: authGuard,
    meta: { title: "Operator Citations Management" },
  },

  //vehicle management
  
  {
    path: "/vehicle-management",
    component: () => import("@/components/vehicle/Vehicle.vue"),
    beforeEnter: authGuard,
    meta: { title: "Vehicle Management", back: "client-vehicle" },
  },
  {
    path: "/vehicles",
    component: () => import("@/components/vehicle/CarrierVehicle.vue"),
    beforeEnter: authGuard,
    meta: { title: "Vehicle Management", back: "client-vehicle" },
  },
  {
    path: "/tow-vehicles",
    component: () => import("@/components/vehicle/TowVehicle.vue"),
    beforeEnter: authGuard,
    meta: { title: "Tow Vehicle Management", back: "tow-vehicles" },
  },
  {
    path: "/carrier-specific-vehicles",
    component: () => import("@/components/vehicle/CarrierSpecificVehicle.vue"),
    beforeEnter: authGuard,
    meta: { title: "Vehicle Management", back: "client-vehicle" },
  },
  {
    path: "/client-logs",
    component: () => import("@/components/vehicle/EditLogs.vue"),
    beforeEnter: authGuard,
    meta: { title: "Client Vehicle Uploads" },
  },
  {
    path: "/tag-types",
    component: () => import("@/components/vehicle/TagTypes.vue"),
    beforeEnter: authGuard,
    meta: { title: "RV Type Management" },
  },
  {
    path: "/toll-tags",
    component: () => import("@/components/vehicle/TollTags.vue"),
    beforeEnter: authGuard,
    meta: { title: "Deactivation Request Management" },
  },
  {
    path: "/carrier-deactivation",
    component: () => import("@/components/vehicle/carrierDeactivation.vue"),
    beforeEnter: authGuard,
    meta: { title: "Operator deactivation request" },
  },
  {
    path: "/vehicle-status",
    component: () => import("@/components/vehicle/VehicleStatus.vue"),
    beforeEnter: authGuard,
    meta: { title: "Vehicle Status" },
  },
  {
    path: "/states",
    component: () => import("@/components/vehicle/States.vue"),
    beforeEnter: authGuard,
    meta: { title: "States" },
  },
  {
    path: "/agencies",
    component: () => import("@/components/vehicle/Agencies.vue"),
    beforeEnter: authGuard,
    meta: { title: "Agencies" },
  },
 
  //employees
  {
    path: "/system-users",
    component: () => import("@/components/employees/SystemUsers.vue"),
    beforeEnter: authGuard,
    meta: { title: "System Users" },
  },
  {
    path: "/users-accounts",
    component: () => import("@/components/accounts/Accounts.vue"),
    beforeEnter: authGuard,
    meta: { title: "Manage Accounts" },
  },

  //transponder endpomts
  {
    path: "/transponders",
    component: () => import("@/components/transponder/OpenOrders.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transponder Management" },
  },
  {
    path: "/tims-open-orders",
    component: () => import("@/components/transponder/TIMSOpenOrders.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transponder Management" },
  },
  {
    path: "/assign-transponders",
    component: () => import("@/components/transponder/AssignTransponders.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transponder Management" },
  },
  {
    path: "/pick-transponders",
    component: () => import("@/components/transponder/PickTransponders.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transponder Management" },
  },
  {
    path: "/pack-transponders",
    component: () => import("@/components/transponder/PackTransponders.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transponder Management" },
  },
  {
    path: "/ship-transponders",
    component: () => import("@/components/transponder/ShipTransponders.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transponder Management" },
  },
  {
    path: "/shipped-transponders",
    component: () => import("@/components/transponder/AllOpenroadsShippedTransponders.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transponder Management" },
  },
  {
    path: "/warehouse-transponders",
    component: () => import("@/components/transponder/OpenroadsWarehouse.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transponder Management" },
  },
  {
    path: "/openroads-transponders-inwarehouse",
    component: () => import("@/components/transponder/Openroadsnventory.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transponder Management" },
  },
  {
    path: "/transponder-location",
    component: () => import("@/components/transponder/TransponderLocation.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transponder Management" },
  },

  { path: "/index.html", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkExactActiveClass: "active",
});

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title =
    "Open Roads - " +
    (toRoute.meta && toRoute.meta.title ? toRoute.meta.title : "");
  next();
});

export default router;
